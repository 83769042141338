import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/contact"

const Contact = ({ location }) => {
  return (
    <Layout location={location} isHeaderTransparent>
      <Breadcrumbs title="Kontakt" isDark />
      <PageHeader
        title="Masz pytania?"
        content="Skontaktuj się z nami"
        isDark
      />
      <Content />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Skontaktuj się z Nami"
    description="Jeśli potrzebujesz informacji na temat produktów i masz pytania - skontaktuj się z nami. Nasz zespół jest po to, by pomóc Ci udekorować Twoje wnętrza."
  />
)

export default Contact
